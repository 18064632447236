import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { configApp } from '../../../../config';
import Moment from 'moment';
import PivotGrid, { Export, FieldChooser, FieldPanel, StateStoring, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Button } from 'devextreme-react/button';
import DatOneMonth from '../../datOneMonth';

class OfficeFuelReportTechSpillsNormed extends Component {

    constructor(props) {
        super(props);        
        this.state = {
          date : Moment(new Date()).set({date:1,hour:0,minute:0,second:0,millisecond:0}),
          pivotGrid : []
        }
    }

    onChangeDate(e) {
      this.setState({
          date : e.date            
      });
    }

    onExecute() {     
        let _date = Moment(this.state.date).format('yyyy-MM-DD');

        this.props.onLoading(true);
        axios.get(configApp.API_CORE + '/ReportsFuel/ReportTechSpillsNormed?dat=' + _date,
         {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
        .then((response) => {
          this.props.onLoading(false);

          if(response.data !== null 
            && response.data !== undefined 
            && response.data.length > 0) {

              let _arr = [];

              response.data.forEach(element => {
                
                const rowData = {
                  date: Moment(element.Date).format('YYYY-MM-DD'),
                  terminalId: element.TerminalId,
                  region: element.Region,
                  terminalName: element.Name,
                  snpAverage: element.SnpAverage,
                  sugAverage: element.SugAverage,
                  snpPlanLogic: element.SnpPlanLogic
                };

                let row1 = {...rowData};

                row1.type = "Сумарно СНП (Бензин + ДТ)";
                row1.spilled = element.SnpSpilled;
                row1.techSpilled = element.TechSpilledSnp;
                row1.techSpilledPlan = element.TechSpilledSnpPlan;

                _arr.push(row1);

                let row2 = {...rowData};

                row2.type = "СУГ";
                row2.spilled = element.SugSpilled;
                row2.techSpilled = element.TechSpilledSug;
                row2.techSpilledPlan = element.TechSpilledSugPlan;

                _arr.push(row2);

                let row3 = {...rowData};

                row3.type = "Бензин";
                row3.spilled = element.PetrolSpilled;
                row3.techSpilled = element.TechSpilledPetrol;
                row3.techSpilledPlan = element.TechSpilledPetrolPlan;

                _arr.push(row3);

                let row4 = {...rowData};

                row4.type = "ДТ";
                row4.spilled = element.DieselSpilled;
                row4.techSpilled = element.TechSpilledDiesel;
                row4.techSpilledPlan = element.TechSpilledDieselPlan;

                _arr.push(row4);

              });

              this.setState({
                pivotGrid : _arr
              });
          }            
       })
       .catch((error) => {
          this.props.onLoading(false);
          console.error(error);
           if (error.response && error.response.data.message) {   
               notify(error.response.data.message, 'error');
              }
           else {
               notify('Не вдалося завантажити дані', 'error');               
           }
         });
    }

    render() {

      const dataSource = new PivotGridDataSource({
        fields: [{
          caption: 'ID АЗК',
          dataField: 'terminalId',
          area: 'filter'
        }, {
          caption: 'Регіон',
          dataField: 'region',
          area: 'filter'
        }, {
          caption: 'Назва АЗК',
          dataField: 'terminalName',
          area: 'row',
          width: 100,
          expanded: true
        }, {
          caption: 'Дата',
          dataField: 'date',
          area: 'filter'
        }, {
          caption: 'Планові накопичення СНП',
          dataField: 'snpPlanLogic',
          area: 'row',
          width: 100,
          expanded: true
        }, {
          caption: 'С/Д реалізація СНП',
          dataField: 'snpAverage',
          dataType: 'number',
          width: 100,
          summaryType: 'avg',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'row',
          expanded: true
        }, 
        {
          caption: 'С/Д реалізація СУГ',
          dataField: 'sugAverage',
          dataType: 'number',
          summaryType: 'avg',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'row',
          expanded: true
        }, {
          caption: 'Тип',
          dataField: 'type',
          area: 'column'
        }, {
          caption: 'Виконання плану інв.',
          dataField: 'techSpilledPlan',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Інвентаризація за місяць',
          dataField: 'techSpilled',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, {
          caption: 'Реалізація за місяць',
          dataField: 'spilled',
          dataType: 'number',
          summaryType: 'sum',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data'
        }, 
        {
          caption: 'Виконання, %',
          dataType: 'number',
          format: { type: 'fixedPoint', precision: 2 },
          area: 'data',
          calculateSummaryValue: function (summaryCell) {
            let _idsTechSpilled = -1;
            let _idxSpilled = -1;

            _idsTechSpilled = summaryCell._descriptions.values.findIndex(e => e.dataField === 'techSpilled');
            _idxSpilled = summaryCell._descriptions.values.findIndex(e => e.dataField === 'spilled');

            if(summaryCell._cell !== undefined && _idsTechSpilled >= 0 && _idxSpilled >= 0) {
              return (summaryCell._cell[_idsTechSpilled] / summaryCell._cell[_idxSpilled] ) * 100;
            }
            
            return 0;
          }
        }
      ],
        store: this.state.pivotGrid
      });

      let _date = Moment(this.state.date).format('yyyyMMDD');

      return (

        <div id="pivot-grid" style={{marginTop : "10px"}}>
        <div style={{float: 'left'}}>
          <DatOneMonth defaultDate={this.state.date} onChangeDate={this.onChangeDate.bind(this)} />
        </div>
        <br style={{clear: 'both'}} />                      
        <Button                            
            text="Завантажити"
            style={{marginTop: '10px'}}
            type="normal"
            icon="download"
            stylingMode="outlined" 
            onClick={this.onExecute.bind(this)}                        
        />
        <div style={{marginTop: "10px"}}>
        <PivotGrid
            id="pivotgrid"
            dataSource={dataSource}
            allowFiltering={true}
            height={window.innerHeight - 250}
            allowSorting={true}
            allowSortingBySummary={true}
            showBorders={true}
            allowExpandAll={true}                
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            showRowGrandTotals={false}
            ref={(ref) => this._pivotGrid = ref} >
      <Export enabled={true} fileName={'ReportTechSpillsNormed_' + _date} />
      <StateStoring enabled={true} type="localStorage" storageKey="pvGridReportTechSpillsNormed" />
      <FieldPanel
        showColumnFields={true}
        showDataFields={true}
        showFilterFields={true}
        showRowFields={true}
        allowFieldDragging={true}
        visible={true}
      />     
      <FieldChooser enabled={true} height={400} />
      <Scrolling mode="virtual" />
    </PivotGrid>
        </div>

      </div>
        )
    }
}

export default connect(
    state => ({
      auth : state.auth,  
      settings : state.settings,
      user : state.user
    }),
    dispatch => ({
      onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
    })
  )(OfficeFuelReportTechSpillsNormed);