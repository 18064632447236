export const _routesStore = [
    {
        id: 1,
        path: '/',
        title: 'Головна',
        nav: [0]
    },
    {
        id: 2,
        path: '/profile',
        title: 'Профіль',
        nav: [1]
    },
    {
        id: 3,
        path: '/settings',
        title: 'Налаштування',
        nav: [1],
        access: {
            allow : [1, 10, 20]
        }
    },
    {
        id: 4,
        path: '/fuels',
        title: 'ПММ',
        nav: [1]
    },
    {
        id: 5,
        path: '/goods',
        title: 'Товари',
        nav: [1]
    },
    {
        id: 6,
        path: '/manager/fuels/reportCheckFuelsWithZReport',
        title: 'Звірка з Z-звітом',
        nav: [1, 4]
    },
    {        
        id: 7,
        path: '/manager/fuels/reportSalesFuelsChecks',
        title: 'Чеки',
        nav: [1, 4]
    },
    {        
        id: 8,
        path: '/manager/fuels/reportFuelsDiscounts',
        title: 'Продажі зі знижками',
        nav: [1, 4]
    },
    {        
        id: 9,
        path: '/manager/fuels/reportFuelsPayTypes',
        title: 'За видами оплат',
        nav: [1, 4]
    },
    {        
        id: 10,
        path: '/manager/fuels/reportFuelsAmountBalance',
        title: 'Рух по резервуарах',
        nav: [1, 4]
    },
    {        
        id: 11,
        path: '/manager/fuels/reportFuelsCounters',
        title: 'Лічильники',
        nav: [1, 4]
    },
    {        
        id: 12,
        path: '/manager/fuels/reportFuelsNonFiscalSpill',
        title: 'Тех. та переливи по ПРК',
        nav: [1, 4]
    },
    {        
        id: 13,
        path: '/manager/fuels/reportFuelsSalesByHour',
        title: 'Продажі ПММ по годинах',
        nav: [1, 4]
    },
    {        
        id: 14,
        path: '/manager/other/reportMoneyMovement',
        title: 'Рух грошей',
        nav: [1, 31]
    },
    {        
        id: 15,
        path: '/manager/fuels/reportFuelsSalesByTalonNumbers',
        title: 'Продажи за талонами',
        nav: [1, 4]
    },
    {        
        id: 16,
        path: '/manager/other/reportCashDocs',
        title: 'Внесення на вилучення готівки',
        nav: [1, 31]
    },
    {        
        id: 17,
        path: '/manager/fuels/report17NP',
        title: 'Звіт 17НП',
        nav: [1, 4]
    },
    {        
        id: 18,
        path: '/manager/fuels/reportFounder',
        title: 'Звіт засновнику',
        nav: [1, 4]
    },
    {        
        id: 19,
        path: '/manager/fuels/reportFuelsCardJournal',
        title: 'Паливні картки',
        nav: [1, 4]
    },
    {        
        id: 20,
        path: '/manager/fuels/reportFuelsPrices',
        title: 'Ціни палива',
        nav: [1, 4]
    },
    {        
        id: 21,
        path: '/manager/fuels/reportFuelsIncome',
        title: 'Прихіди ПММ',
        nav: [1, 4]
    },
    {        
        id: 22,
        path: '/manager/fuels/reportFuelsFullTanks',
        title: 'Замовлення "Повний бак"',
        nav: [1, 4]
    },
    {        
        id: 23,
        path: '/manager/goods/reportSalesGoods',
        title: 'За артикулами',
        nav: [1, 5]
    },
    {        
        id: 24,
        path: '/manager/goods/reportGoodsChecks',
        title: 'Чеки',
        nav: [1, 5]
    },
    {        
        id: 25,
        path: '/manager/goods/reportGoodsCancelChecks',
        title: 'Вилучені позиції',
        nav: [1, 5]
    },
    {        
        id: 26,
        path: '/manager/goods/reportSalesGoodsDocMove',
        title: 'Рух товару',
        nav: [1, 5]
    },
    {        
        id: 27,
        path: '/manager/fuels/reportMonoTransactions',
        title: 'MonoBank транзакції',
        nav: [1, 4]
    },
    {        
        id: 28,
        path: '/manager/fuels/reportTaxiTransactions',
        title: 'E-Картка транзакції',
        nav: [1, 4]
    },
    {        
        id: 29,
        path: '/manager/fuels/reportNPList',
        title: 'Податкові накладні',
        nav: [1, 4]
    },
    {        
        id: 30,
        path: '/manager/other/reportCashbackTransactions',
        title: 'Видача готівки',
        nav: [1, 31]
    },
    {
        id: 31,
        path: '/other',
        title: 'Інше',
        nav: [1]
    },
    {
        id: 32,
        path: '/general',
        title: 'Загальні',
        nav: [1]
    },
    {
        id: 33,
        path: '/office/general/reportMoneyMovement',
        title: 'Рух грошей',
        nav: [1, 32]
    },
    {
        id: 34,
        path: '/office/general/reportCashDocs',
        title: 'Операції з грошима',
        nav: [1, 32]
    },
    {
        id: 35,
        path: '/office/general/reportCashDocForDay',
        title: 'Звіт касира за день',
        nav: [1, 32]
    },
    {
        id: 36,
        path: '/office/fuel/reportComplex',
        title: 'Комплексний',
        nav: [1, 4]
    },
    {
        id: 37,
        path: '/office/general/reportTotalSales',
        title: 'Реалізація',
        nav: [1, 32]
    },
    {
        id: 38,
        path: '/office/goods/reportGoodsChecks',
        title: 'Чеки',
        nav: [1, 5]
    },
    {
        id: 39,
        path: '/office/goods/reportGoodsSalesTaxes',
        title: 'За податками',
        nav: [1, 5]
    },
    {
        id: 40,
        path: '/office/goods/reportGoodsSalesByHours',
        title: 'Продаж за годинами',
        nav: [1, 5]
    },
    {
        id: 41,
        path: '/office/fuel/report17NP',
        title: 'Форма 17НП',
        nav: [1, 4]
    },
    {
        id: 42,
        path: '/office/fuel/reportFuelsChecks',
        title: 'Чеки',
        nav: [1, 4]
    },
    {
        id: 43,
        path: '/office/fuel/reportFuelsPaytypes',
        title: 'За видами оплат',
        nav: [1, 4]
    },
    {
        id: 44,
        path: '/office/fuel/reportFuelSalesCash',
        title: 'За готівку',
        nav: [1, 4]
    },
    {
        id: 45,
        path: '/office/fuel/reportFuelsTankSaldos',
        title: 'Рух по резервуарам',
        nav: [1, 4]
    },
    {
        id: 46,
        path: '/office/fuel/reportFuelsDiscounts',
        title: 'За знижками',
        nav: [1, 4]
    }, 
    {
        id: 47,
        path: '/office/fuel/reportFuelsSalesByHours',
        title: 'Продажі за годинами',
        nav: [1, 4]
    }, 
    {
        id: 48,
        path: '/office/fuel/reportFiscalRegister',
        title: 'Фіскальний',
        nav: [1, 4]
    }, 
    {
        id: 49,
        path: '/office/fuel/reportFuelsRealRest',
        title: 'Залишки палива',
        nav: [1, 4]
    }, 
    {
        id: 50,
        path: '/office/fuel/reportFuelSalesGrid',
        title: 'Реалізація',
        nav: [1, 4]
    }, 
    {
        id: 51,
        path: '/office/fuel/reportFuelCompareSales',
        title: 'Порівняння реалізації',
        nav: [1, 4]
    }, 
    {
        id: 52,
        path: '/office/fuel/reportFuelsPrices',
        title: 'Ціни палива',
        nav: [1, 4]
    }, 
    {
        id: 53,
        path: '/office/fuel/reportFuelIncomes',
        title: 'Прихід палива',
        nav: [1, 4]
    }, 
    {
        id: 54,
        path: '/office/talons/reportAbuseTalons',
        title: 'Зловживання талонами',
        nav: [1, 57]
    }, 
    {
        id: 55,
        path: '/office/talons/reportFuelsChecksTalons',
        title: 'Чеки з талонами',
        nav: [1, 57]
    },
    {
        id: 56,
        path: '/office/talons/reportCheckUseTalons',
        title: 'Перевірка талонів',
        nav: [1, 57]
    },
    {
        id: 57,
        path: '/talons',
        title: 'Талони',
        nav: [1]
    },
    {
        id: 58,
        path: '/office/general/reportCashbackTransactions',
        title: 'Видача готівки',
        nav: [1, 32]
    },
    {
        id: 59,
        path: '/office/general/reportFuelsGoodsPaytypes',
        title: 'За видами оплат',
        nav: [1, 32]
    },
    {
        id: 60,
        path: '/office/general/reportLottoPayTransactions',
        title: 'Lotto. Видача виграшів',
        nav: [1, 32]
    },
    {
        id: 61,
        path: '/office/general/reportBankTransactionsDiff',
        title: 'Банк.транзакції (порівняння)',
        nav: [1, 32]
    },
    {
        id: 62,
        path: '/office/fuel/reportFuelRestGauges',
        title: 'Залишки пального (рівнемір)',
        nav: [1, 4]
    },
    {
        id: 63,
        path: '/office/talons/reportTalonsActive',
        title: 'Активні залишки (по клієнтам)',
        nav: [1, 57]
    },
    {
        id: 64,
        path: '/office/talons/reportTalonsPayOff',
        title: 'Погашені талони (по клієнтам)',
        nav: [1, 57]
    },
    {
        id: 65,
        path: '/office/talons/reportTalonsInfo',
        title: 'Інформація по талонам',
        nav: [1, 57]
    },
    {
        id: 66,
        path: '/office/general/reportTerminalsInfo',
        title: 'Інформація по АЗК',
        nav: [1, 32]
    },
    {
        id: 67,
        path: '/manager/goods/reportGoodsAutoreturn',
        title: 'Автоповернення',
        nav: [1, 5]
    },
    {
        id: 68,
        path: '/cards',
        title: 'Картки',
        nav: [1]
    },
    {
        id: 69,
        path: '/office/cards/reportTaxiTransactions',
        title: 'E-Картка транзакції (АЗК)',
        nav: [1, 68]
    },
    {
        id: 70,
        path: '/office/fuel/reportSpillPrice',
        title: 'Ціна проливу (ПК та Талони)',
        nav: [1, 4]
    },
    {
        id: 71,
        path: '/office/cards/reportTaxiAvgSpill',
        title: 'E-Картка. Ціна проливу',
        nav: [1, 68]
    },
    {
        id: 72,
        path: '/office/cards/reportTaxiRests',
        title: 'E-Картка. Залишки клієнтів',
        nav: [1, 68]
    },
    {
        id: 73,
        path: '/office/fuel/reportFuelSalesDailyGrid',
        title: 'Реалізація (днями)',
        nav: [1, 4]
    },
    {
        id: 74,
        path: '/office/cards/reportEngineTransactions',
        title: 'Engine транзакції',
        nav: [1, 68]
    },
    {
        id: 75,
        path: '/office/cards/reportTNPPortEngine',
        title: 'Engine. Незавершені транзакції',
        nav: [1, 68]
    },
    {
        id: 76,
        path: '/office/cards/reportEngineBalance',
        title: 'Engine. Залишок по грош.рахунках',
        nav: [1, 68]
    },
    {
        id: 77,
        path: '/office/cards/reportRevisePortEngine',
        title: 'Engine. Звірка з ПОРТ',
        nav: [1, 68]
    },
    {
        id: 78,
        path: '/office/cards/reportRevisePortEngineFuels',
        title: 'Engine. Звірка з ПОРТ (по видам пального)',
        nav: [1, 68]
    },
    {
        id: 79,
        path: '/office/cards/reportFuelWallet',
        title: 'E-Картка транзакції (гаманець)',
        nav: [1, 68]
    },
    {
        id: 80,
        path: '/office/cards/reportRevisePortTaxi',
        title: 'E-Картка. Звірка з ПОРТ',
        nav: [1, 68]
    },
    {
        id: 81,
        path: '/office/cards/reportRevisePortTaxiFuels',
        title: 'E-Картка. Звірка з ПОРТ (по видам пального)',
        nav: [1, 68]
    },
    {
        id: 82,
        path: '/office/general/reportCashDocsDiff',
        title: 'Різниця вилучення/інкасація',
        nav: [1, 32]
    },
    {
        id: 83,
        path: '/office/general/reportNotActivityStation',
        title: 'Бездіяльність АЗК',
        nav: [1, 32]
    },
    {
        id: 84,
        path: '/office/general/reportShiftsChange',
        title: 'Перезмінки на АЗК',
        nav: [1, 32]
    },
    {
        id: 85,
        path: '/office/general/reportTotalSales2',
        title: 'Загальний продаж',
        nav: [1, 32]
    },
    {
        id: 86,
        path: '/office/general/reportDocTaxes',
        title: 'Податкові накладні',
        nav: [1, 32]
    },
    {
        id: 87,
        path: '/office/general/reportCheckCopy',
        title: 'Друк дублікату чеків',
        nav: [1, 32]
    },
    {
        id: 88,
        path: '/office/fuel/reportFuelFeedRate',
        title: 'Швидкість проливу ПРК',
        nav: [1, 4]
    },
    {
        id: 89,
        path: '/office/fuel/reportFuelIncomesSales',
        title: 'Приходи та продаж палива',
        nav: [1, 4]
    },
    {
        id: 90,
        path: '/office/fuel/reportFuelSalesAmountChecks',
        title: 'Частка продажів без картки ПЛ',
        nav: [1, 4]
    },
    {
        id: 91,
        path: '/office/fuel/reportFuelsCheckCounters',
        title: 'Звірка проливу (касса / лічильники ПРК)',
        nav: [1, 4]
    },
    {
        id: 92,
        path: '/office/fuel/reportFuelsChecksCount',
        title: 'Середній чек продажу',
        nav: [1, 4]
    },
    {
        id: 93,
        path: '/office/fuel/reportFuelsFullTanks',
        title: 'Замовлення "Повний бак"',
        nav: [1, 4]
    },
    {
        id: 94,
        path: '/office/fuel/reportFuelsPaytypes2',
        title: 'За видами оплат 2',
        nav: [1, 4]
    },
    {
        id: 95,
        path: '/office/fuel/reportLoyaltyFuelsChecks',
        title: 'Чеки за картками ПЛ',
        nav: [1, 4]
    },
    {
        id: 96,
        path: '/office/fuel/reportFuelInventory',
        title: 'Інвентаризація палива',
        nav: [1, 4]
    },
    {
        id: 97,
        path: '/office/fuel/reportFuelInventoryMonth',
        title: 'Інвентаризація палива (по місяцях)',
        nav: [1, 4]
    },
    {
        id: 98,
        path: '/office/fuel/reportFuelSalesBrsm2',
        title: 'Продаж по Б2',
        nav: [1, 4]
    },
    {
        id: 99,
        path: '/office/fuel/reportRestManualEdit',
        title: 'Ручне коригування залишків',
        nav: [1, 4]
    },
    {
        id: 100,
        path: '/office/goods/reportSalesGoodsFilter',
        title: 'Продаж за фільтром',
        nav: [1, 5]
    },
    {
        id: 101,
        path: '/office/goods/reportSalesWine',
        title: 'Продаж вина',
        nav: [1, 5]
    },
    {
        id: 102,
        path: '/office/goods/reportSalesWasher',
        title: 'Продаж омивача',
        nav: [1, 5]
    },
    {
        id: 103,
        path: '/office/tspill/reportTechSpills',
        title: 'Тех.проливи',
        nav: [1, 112]
    },
    {
        id: 104,
        path: '/office/tspill/reportTechSpillsTerminalDetailed',
        title: 'Тех.проливи (проливи детально)',
        nav: [1, 112]
    },
    {
        id: 105,
        path: '/office/tspill/reportTechSpillsWithSum',
        title: 'Тех.проливи (з сумами)',
        nav: [1, 112]
    },
    {
        id: 106,
        path: '/office/tspill/reportTechSpillsWithSumDiscounts',
        title: 'Тех.проливи (зі знижками)',
        nav: [1, 112]
    },
    {
        id: 107,
        path: '/office/tspill/reportTechSpillsDifferences',
        title: 'Тех.проливи (порівняння)',
        nav: [1, 112]
    },
    {
        id: 108,
        path: '/office/tspill/reportTechSpillsNormed',
        title: 'Тех.проливи (норми)',
        nav: [1, 112]
    },
    {        
        id: 109,
        path: '/manager/fuels/reportTechSpillsNormed',
        title: 'Тех.проливи (норми)',
        nav: [1, 4]
    },
    {        
        id: 110,
        path: '/manager/fuels/reportFiscalRegister',
        title: 'Фіскальний',
        nav: [1, 4]
    },
    {
        id: 111,
        path: '/office/tspill/reportTechSpillsTerminalDetailedRepair',
        title: 'Тех.проливи (ремонт/механіки)',
        nav: [1, 112]
    },
    {
        id: 112,
        path: '/tspill',
        title: 'Тех.проливи',
        nav: [1]
    },
    {
        id: 113,
        path: '/office/fuel/reportFuelsTankAlarm',
        title: 'Аварійні проливи',
        nav: [1, 4]
    },
    {
        id: 114,
        path: '/office/cards/reportRevisePortEngineVS',
        title: 'Engine. ВС звірка з ПОРТ',
        nav: [1, 68]
    },
    {
        id: 115,
        path: '/office/cards/reportRevisePortEngineFuelsVS',
        title: 'Engine. ВС звірка з ПОРТ (по видам пального)',
        nav: [1, 68]
    },
    {
        id: 116,
        path: '/office/fuel/reportFuelsDiscountsSummary',
        title: 'За знижками (зведений)',
        nav: [1, 4]
    },
    {
        id: 117,
        path: '/office/goods/reportSalesGlovo',
        title: 'Продаж Glovo',
        nav: [1, 5]
    },  
    {
        id: 118,
        path: '/office/cards/reportProblemBackgroundTrans',
        title: 'Engine. Б2. Потенційно проблемні транзакції',
        nav: [1, 68]
    },  
    {
        id: 119,
        path: '/office/cards/reportSalesFuelsDiffCardsTrans',
        title: 'Engine. Чеки ПММ. Розбіжності',
        nav: [1, 68]
    },
    {
        id: 120,
        path: '/office/general/reportDeductionZSUByClients',
        title: 'Відрахування ЗСУ. По клієнтам',
        nav: [1, 32]
    },
    {
        id: 121,
        path: '/office/general/reportDeductionZSUTotalStations',
        title: 'Відрахування ЗСУ. По АЗК',
        nav: [1, 32]
    },
    {
        id: 122,
        path: '/wsspill',
        title: 'ВС проливи',
        nav: [1]
    },   
    {
        id: 123,
        path: '/office/wsspill/reportWsSpills',
        title: 'ВС проливи (загальний)',
        nav: [1, 122]
    },
    {
        id: 124,
        path: '/office/wsspill/reportWsSpillsDetailed',
        title: 'ВС проливи (детально)',
        nav: [1, 122]
    },
    {
        id: 125,
        path: '/office/wsspill/reportWsSpillsDifferences',
        title: 'ВС проливи (порівняння)',
        nav: [1, 122]
    },
    {
        id: 126,
        path: '/office/general/reportBankTransactionsCancelPayment',
        title: 'Банк.транзакції (скасування)',
        nav: [1, 32]
    },
    {
        id: 127,
        path: '/office/general/reportVisitsUsersStations',
        title: 'Відвідування АЗК',
        nav: [1, 32]
    },
    {
        id: 128,
        path: '/office/tspill/reportTechSpillsHistoryLog',
        title: 'Тех.проливи (історія операцій)',
        nav: [1, 112]
    },
    {
        id: 129,
        path: '/office/wsspill/reportEngineWSBalance',
        title: 'ВС проливи. Залишки по рахунках',
        nav: [1, 122]
    },
    {
        id: 130,
        path: '/office/wsspill/reportEngineWSAutoWithdraw',
        title: 'ВС проливи. Автосписання по рахунках',
        nav: [1, 122]
    },
    {
        id: 131,
        path: '/wallet',
        title: 'Скарбничка',
        nav: [1]
    },
    {
        id: 132,
        path: '/office/wallet/reportWalletBalances',
        title: 'Баланс по клієнтам',
        nav: [1, 131]
    },
    {
        id: 133,
        path: '/office/wallet/reportWalletReplenish',
        title: 'Поповнення скарбничкі',
        nav: [1, 131]
    },
    {
        id: 134,
        path: '/office/cards/reportWogSpills',
        title: 'WOG. Проливи',
        nav: [1, 68]
    },
    {
        id: 135,
        path: '/office/cards/reportInconsistantWogSpills',
        title: 'WOG. Потенційно проблемні транзакції',
        nav: [1, 68]
    },
    {
        id: 136,
        path: '/office/general/reportCashRegUsePrintRibbon',
        title: 'Використання стрічки РРО',
        nav: [1, 32]
    },
]