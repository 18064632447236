import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from 'devextreme/ui/notify';
import DatFromDatTo from '../../datFromDatTo';
import axios from 'axios';
import { configApp } from '../../../../config';
import DataGrid, { Column, Export, StateStoring, Paging, ColumnChooser, MasterDetail, Pager } from 'devextreme-react/data-grid';
import Moment from 'moment';

class OfficeCardsReportWogProblemTrans extends Component {

  constructor(props) {
    super(props);        
    this.state = {
        dateFrom : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}).add(-7,'days'),
        dateTo : Moment(new Date()).set({hour:0,minute:0,second:0,millisecond:0}),
        dataGrid : []
    }
}

onChangeDates(e) {
  this.setState({
      dateFrom : e.dateFrom,
      dateTo : e.dateTo             
  });
}

onLoad() {
  this.clearFilterDataGrid();
  this.onExecute();
}

onExecute() {             
    let _datFrom = Moment(this.state.dateFrom).format('YYYY-MM-DD');
    let _datTo = Moment(this.state.dateTo).format('YYYY-MM-DD');

    this.props.onLoading(true);
    axios.get(configApp.API_CORE + '/ReportsFuel/ReportInconsistantWogSpills?dateFrom=' + _datFrom + '&dateTo=' + _datTo, 
      {headers : { Authorization: 'Bearer ' + this.props.auth.AccessToken }})
    .then((response) => {
      this.props.onLoading(false);
      // this.setState({
      //     dataGrid : response.data
      // });
      
      if(response.data) {
        let _data = response.data;
        let _arr = [];

        const errorDescriptions = {
          AmountError: 'Різниця літрів',
          OperationError: 'Різниця статусів',
          SumError: 'Різниця суми до сплати',
        };

        if(_data.Inconsistant &&
           Array.isArray(_data.Inconsistant) &&
            _data.Inconsistant.length > 0) {

              _data.Inconsistant.forEach(f => {
                const _descProblem = Object.keys(errorDescriptions)
                  .filter(key => f[key]) // Залишаємо лише ті ключі, які є `true` в `f`
                  .map(key => errorDescriptions[key]); // Замінюємо ключі їх описами
              
                _arr.push({
                  typeProblem: 'Inconsistant',
                  typeProblemUkr: 'Не відповідність даних транзакцій',
                  descProblem: _descProblem.join(', '),
                  dat : f.sf?.Dat ?? f.wg?.Dat,
                  terminalId: f.sf?.TerminalId ?? f.wg?.TerminalId,
                  shiftId: f.sf?.ShiftId ?? f.wg?.ShiftId,
                  trans: [{wog: f.wg, sf: f.sf}]
                });
              });
        }

        if(_data.NoSales &&
            Array.isArray(_data.NoSales) &&
             _data.NoSales.length > 0) {

              _data.NoSales.forEach(f => {

                _arr.push({
                  typeProblem: 'NoSales',
                  typeProblemUkr: 'Каса. Відсутність даних продажу',
                  descProblem: 'Відсутні дані продажу на касі',
                  dat : f.Dat,
                  terminalId: f.TerminalId,
                  shiftId: f.ShiftId,
                  trans: [{wog: f}]
                });
              });              
         }

        if(_data.NoWog &&
            Array.isArray(_data.NoWog) &&
             _data.NoWog.length > 0) {
 
              _data.NoWog.forEach(f => {

              _arr.push({
                typeProblem: 'NoWog',
                typeProblemUkr: 'WOG. Некоректність даних продажу',
                descProblem: 'WOG: ' + f.ErrorType,
                dat : f.Dat,
                terminalId: f.TerminalId,
                shiftId: f.ShiftId,
                trans: [{sf: f}]
              });
            });  
        }
        
        this.setState({
          dataGrid : _arr
        });

      }

   })
   .catch((error) => {
    console.error(error);
        this.props.onLoading(false);
       if (error.response && error.response.data.message) {   
           notify(error.response.data.message, 'error');
          }
       else {
           notify('Не вдалося завантажити дані', 'error');               
       }
     });
}

clearFilterDataGrid() {
  this.dataGrid.instance.clearFilter();
}

onToolbarPreparing(e) {
  const exportBtnItem = e.toolbarOptions.items.find(  
    item => item.name === "exportButton"  
  );  
  const exportBtnIndex = e.toolbarOptions.items.indexOf(exportBtnItem);
  
  e.toolbarOptions.items[exportBtnIndex] = {  
    location: "after",  
    locateInMenu: "auto",  
    sortIndex: 30,  
    widget: "dxButton",  
    options: {  
      text: "В Excel",  
      icon: "export-excel-button",  
      hint: "Експорт в Excel",  
      elementAttr: {  
        "class": "dx-datagrid-export-button"  
      },  
      onClick: function() {  
        e.component.exportToExcel(false);  
      }  
    }  
  };  
  
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',            
        onClick: this.onExecute.bind(this),
        hint: 'Оновити'
      }},{
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'notequal',            
        onClick: this.clearFilterDataGrid.bind(this),
        hint: 'Очистити всі фільтри'
      }
    },{
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'download',
        text: 'Завантажити',            
        onClick: this.onLoad.bind(this),
        hint: 'Завантажити дані'
      }
    });
  }

  onCellPrepared(e) {
    // if(e.rowType === 'data') {
    //   if(e.columnIndex === 11) {
    //     if(e.value !== 0) {
    //       e.cellElement.classList.add('data-grid-error');
    //     }
    //   }
    // }        
  }

  TransInfoDet(e) {
    return (
      <div>
      <div><b>Деталізація транзакцій</b></div>
      <br />
      <DataGrid
            id="gridTransInfoDet"              
            dataSource={e.data.data.trans}
            allowColumnResizing={true}
            columnAutoWidth={true}
            showBorders={true}
            rowAlternationEnabled={true}
            filterRow={{applyFilter : true, visible : true}}>                            
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20]}
              showInfo={true} />
           <Column dataField="sf.Checknum" caption="(Каса) Чек №" dataType="number" /> 
           <Column dataField="sf.FuelId" caption="(Каса) Паливо №" dataType="number" /> 
           <Column dataField="wog.FuelId" caption="(WOG) Паливо №" dataType="number" /> 
           <Column dataField="wog.Id" caption="(WOG) ID" dataType="number" /> 
           <Column dataField="wog.ServiceTransaction" caption="(WOG) Транзакція №" dataType="number" /> 
           <Column dataField="wog.Price" caption="(WOG) Ціна" dataType="number" /> 
           <Column dataField="sf.Ordered" caption="(Каса) К-ть літрів" dataType="number" /> 
           <Column dataField="wog.Amount" caption="(WOG) К-ть літрів" dataType="number" /> 
           <Column dataField="sf.Summa" caption="(Каса) Сума грн" dataType="number" /> 
           <Column dataField="wog.Summa" caption="(WOG) Сума грн." dataType="number" /> 
          </DataGrid> 
      </div>
    );
  }  

render() {

  let _dateFrom = Moment(this.state.dateFrom).format('yyyyMMDD');
  let _datTo = Moment(this.state.dateTo).format('yyyyMMDD');

  return (

    <div style={{marginTop : "10px"}}>
     <DatFromDatTo defaultDateFrom={this.state.dateFrom} defaultDateTo={this.state.dateTo} onChangeDates={this.onChangeDates.bind(this)} />
     <div style={{marginTop: "10px"}}>
     <DataGrid
           ref={(ref) => this.dataGrid = ref}
           id="gridReportWogProblemTrans"              
           dataSource={this.state.dataGrid}
           allowColumnReordering={true}
           allowColumnResizing={true}
           columnAutoWidth={true}
           showBorders={true}         
           rowAlternationEnabled={true}
           onToolbarPreparing={this.onToolbarPreparing.bind(this)}     
           filterRow={{applyFilter : true, visible : true}} >
               
           <Export enabled={true} fileName={'ReportWogProblemTrans_' + _dateFrom + '_' + _datTo} />               
           <StateStoring enabled={true} type="localStorage" storageKey="ReportWogProblemTrans" />
           <Paging defaultPageSize={100} />
           <ColumnChooser enabled={true} />
           <Column dataField="dat" caption="Дата" dataType="datetime" /> 
           <Column dataField="terminalId" caption="ID АЗК" />
           <Column dataField="shiftId" caption="Зміна №" />
           <Column dataField="typeProblemUkr" caption="Тип проблеми" />
           <Column dataField="descProblem" caption="Опис проблеми" />
           <MasterDetail
            enabled={true}
            component={this.TransInfoDet.bind(this)} />
           
         </DataGrid>
     </div>

   </div>
 ) 
}
}

export default connect(
state => ({
  auth : state.auth,  
  settings : state.settings,
  user : state.user
}),
dispatch => ({
    onLoading(item) {
        dispatch({type: 'LOADING_SHOW', payload: item});
    }
})
  )(OfficeCardsReportWogProblemTrans);